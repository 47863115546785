<template>
  <div
    class="mx-auto max-w-full sm:px-6 lg:grid lg:grid-cols-12 lg:gap-8 lg:px-8"
  >
    <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
      <products-sidebar />
    </div>
    <main class="lg:col-span-9 xl:col-span-10"><router-view /></main>
  </div>
</template>

<script>
import ProductsSidebar from "@/common/components/navigation/sidebar/_products.vue";

export default {
  components: {
    ProductsSidebar,
  },
  setup() {
    return {
      //   ProductsSidebar,
    };
  },
};
</script>
