<template>
  <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
    <div class="space-y-1 pb-8">
      <router-link
        v-for="item in navigation"
        :key="item.id"
        :to="item.href"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <component
          :is="item.icon"
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span class="truncate"> {{ item.name }} </span>
      </router-link>
    </div>
  </nav>
</template>

<script>
// import { reactive, toRefs } from 'vue';
import {
  CogIcon,
  PuzzleIcon,
  LightningBoltIcon,
  ShoppingCartIcon,
  UserGroupIcon,
  CreditCardIcon,
  ShieldCheckIcon,
  ColorSwatchIcon,
  ViewBoardsIcon,
  AcademicCapIcon,
  OfficeBuildingIcon,
  DocumentReportIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/outline";

const navigation = [
  {
    id: 0,
    name: "Activity",
    href: "/products/activity",
    icon: LightningBoltIcon,
    current: false,
  },
  {
    id: 1,
    name: "Overview",
    href: "/products/overview",
    icon: UserGroupIcon,
    current: false,
  },
  {
    id: 2,
    name: "Warehouses",
    href: "/products/warehouses",
    icon: OfficeBuildingIcon,
    current: false,
  },
  {
    id: 3,
    name: "Products",
    href: "/products/all",
    icon: PuzzleIcon,
    current: false,
  },
  {
    id: 4,
    name: "Orders",
    href: "/products/orders",
    icon: ShoppingCartIcon,
    current: false,
  },
];

export default {
  components: {
    CogIcon,
    PuzzleIcon,
    ShoppingCartIcon,
    LightningBoltIcon,
    UserGroupIcon,
    CreditCardIcon,
    ShieldCheckIcon,
    ColorSwatchIcon,
    ViewBoardsIcon,
    AcademicCapIcon,
    OfficeBuildingIcon,
    DocumentReportIcon,
    QuestionMarkCircleIcon,
  },
  setup() {
    return {
      navigation,
    };
  },
};
</script>
